<template>
  <div>
    <v-card class="mt-4" v-if="$vuetify.breakpoint.xs" :loading="loading">
      <v-system-bar>vergangene Kontrollen (nicht mehr änderbar)</v-system-bar>
      <AttendanceChecksList :items="items" />
    </v-card>
    <v-card class="mt-4" v-else :loading="loading">
      <v-system-bar>vergangene Kontrollen (nicht mehr änderbar)</v-system-bar>
      <AttendanceChecksTable :items="items" />
    </v-card>
  </div>
</template>

<script>
import AttendanceChecksList from "./components/AttendanceChecksList.vue";
import AttendanceChecksTable from "./components/AttendanceChecksTable.vue";
export default {
  components: { AttendanceChecksList, AttendanceChecksTable },
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const checks = await this.apiList({
        resource: "absence/attendancechecks",
        query: "filter=past",
      });
      this.loading = false;
      this.items = checks.sort((a, b) => this.compareItems(a, b));
    },
    compareItems(a, b) {
      if (a.date > b.date) return -1;
      if (a.date < b.date) return 1;
      if (a.startTime > b.startTime) return -1;
      if (a.startTime < b.startTime) return 1;
      return 0;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
